// Variables
$chat-background-color: #f6f6f7;
$chat-header-display: none;
$chat-bot-avatar-display: none;

.react-chatbot-kit-chat-header {
  display: $chat-header-display;
}

.react-chatbot-kit-chat-container {
  width: 100%;
  height: calc(100vh - 54px);
  background-color: $chat-background-color;
}

.react-chatbot-kit-chat-message-container {
  padding: 0px;
  height: 95%;
  overflow-x: hidden;
}

.react-chatbot-kit-chat-input-container {
  display: none;
}

.react-chatbot-kit-chat-input {
  height: 59px;
  border-top: 1px solid #e3e3e3;
  outline: none;
  font-size: 14px;
}

.react-chatbot-kit-chat-btn-send {
  border-top: 1px solid #e3e3e3;
  background-color: #ffffff;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #2d7973;
  width: 20.75px;
}

.react-chatbot-kit-user-chat-message-arrow {
  display: $chat-header-display;
}

.react-chatbot-kit-user-avatar-container {
  display: $chat-bot-avatar-display;
}

.react-chatbot-kit-chat-inner-container {
  height: 100%;
  border-radius: 0px;
  background-color: $chat-background-color;
}

.react-chatbot-kit-chat-bot-avatar-container {
  display: $chat-bot-avatar-display;
}

.react-chatbot-kit-chat-bot-message-container {
  &:nth-child(2) {
    display: none;
  }
}

.react-chatbot-kit-user-chat-message-container {
  display: none;
}

.react-chatbot-kit-chat-bot-message {
  padding: 7px 22px;
  margin-right: 17px;
  margin-bottom: 8px;
  font-size: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #2d7973;
}

.react-chatbot-kit-chat-bot-message span {
  height: 30px;
  display: flex;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar {
  height: 44px;
  width: auto;
  max-width: 100%;
}

.react-chatbot-kit-chat-bot-message-container {
  margin: 0px;
}

.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
}

/* New styles for the elements in the JSX */
.chatbot-header-container {
  height: 4rem;
  border: 0px 0px 1px 0px;
  background: #ffffff;
  padding-left: 2.6rem;
}

.chatbot-header {
  display: flex;
  width: 237.64px;
  height: 30px;
  margin-top: 25px;
  margin-bottom: 24px;
}